<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 작업종류 -->
          <c-select
            codeGroupCd="SOP_WORK_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="attrVal1Contain"
            label="작업종류"
            v-model="searchParam.attrVal1Contain"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="작업 보호구 목록"
      tableId="workCheckItem"
      :columns="grid.columns"
      :data="grid.data"
      :filtering="false"
      :columnSetting="false"
      :editable="editable"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" :showLoading="false" label="LBLADD" icon="add" @btnClicked="add" />
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="POST"
            label="LBLSAVE" 
            icon="save" 
            @beforeAction="saveWorkProtectiveGear"
            @btnCallback="saveWorkProtectiveGearCallback"/>
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <q-dialog v-model="prompt.visible" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <!-- 코드 -->
          <div class="text-h6">코드</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input dense v-model="prompt.code" autofocus @keyup.enter="setMst" />
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <!-- 취소 -->
          <q-btn flat :label="$label('LBLCANCEL')" @click="cancel" />
          <q-btn flat label="적용" @click="accept" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'workProtectiveGear',
  data() {
    return {
      searchParam: {
        srchCodeGrpCd: 'SOP_PROTECTIVE_GEAR_CD',
        attrVal1Contain: null,
        useFlag: 'Y',
      },
      grid: {
        columns: [
          {
            name: 'code',
            field: 'code',
            label: '작업 보호구 코드',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'codeNameKr',
            field: 'codeNameKr',
            label: '작업 보호구명',
            align: 'left',
            sortable: true,
            required: true,
            style: 'width:200px',
            type: 'text',
          },
          {
            name: 'attrVal1',
            field: 'attrVal1',
            label: '작업종류',
            align: 'center',
            sortable: true,
            style: 'width:350px',
            type: 'multiSelect',
            isArray: false,
            itemText: 'codeName',
            itemValue: 'code',
            codeGroupCd: 'SOP_WORK_TYPE_CD'
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            sortable: true,
            style: 'width:80px',
            type: 'check',
            true: 'Y',
            false: 'N',
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: 'LBLSORTORDER',
            align: 'center',
            style: 'width:80px',
            sortable: true,
            type: 'number',
          },
        ],
        data: [],
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      prompt: {
        visible: false,
        code: '',
      },
      editable: true,
      listUrl: '',
      validUrl: '',
      saveUrl: '',
      isSave: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sys.code.mst.list.multi.url;
      this.validUrl = selectConfig.sys.code.mst.valid.url;
      this.saveUrl = transactionConfig.sys.code.mst.save.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    add() {
      this.prompt.visible = true;
    },
    accept() {
      this.$http.url = this.validUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        srchCodeGrpCd: 'SOP_PROTECTIVE_GEAR_CD',
        srchCode: this.prompt.code,
      };
      this.$http.request((_result) => {
        if (_result.data === 0) {
          if (!this.grid.data) {
            this.grid.data = [];
          }
          let _order = 0;
          let cnt = this.grid.data.length;
          if (cnt == 0) {
            _order = 1;
          } else if (cnt == 1) {
            _order = 10;
          } else {
            _order = this.grid.data[cnt-1].sortOrder + 10;
          }
          this.grid.data.push({
            codeGrpCd: 'SOP_PROTECTIVE_GEAR_CD',
            code: this.prompt.code,
            codeNameKr: '',
            attrVal1: '',
            attrVal2: '',
            attrVal3: '',
            attrVal4: '',
            useFlag: 'Y',
            sortOrder: _order,
            editFlag: 'C',
          })
          this.prompt.visible = false;
        } else {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', /* 안내 */
            message: '해당 코드는 사용중 입니다.', // 해당 코드는 사용중 입니다.
            type: 'info', // success / info / warning / error
          });
        }
      },);
    },
    cancel() {
      this.prompt.visible = false;
      this.prompt.code = '';
    },
    saveWorkProtectiveGear() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {            
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveWorkProtectiveGearCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
